import React, {useEffect, useState} from "react";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link, Redirect, useParams} from "react-router-dom";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import ComponentLoader from "../../../shared/components/componentLoader/ComponentLoader";
import TopSummary from "../../stats/components/TopSummary";
import LiveSale from "../../stats/components/liveSale/LiveSale";
import SalesPostEditWrapper from "../SalesPostEditWrapper";
import {Helmet} from "react-helmet";
import {RouterPaths} from "../../../../routes/RouterPath";
import PostNotification from "../components/postNotification/PostNotification";
import VideoPreview from "../components/videoPreview/VideoPreview";
import NoDataModal from "../../list/components/NoDataModal";

const SalesPostStatsForm = () => {

    const [dataLoaded, setDataLoaded] = useState(false);
    const {getLangText} = useLang();
    const {id} = useParams();
    const [showNoStatsModal, setShowNoStatsModal] = useState(false);

    const {
        data: {values, updateValues},
        form: {isWaiting, loadData},
    } = useForm({
        FormActions,
        params: {
            id,
        }
    });

    const hasStats = values.shop_post_id || !+id;

    useEffect(() => {
        if (!isWaiting) {
            setDataLoaded(true);
        }
    }, [isWaiting]);

    if (values.notFound) return <Redirect to={RouterPaths.SalesPost}/>

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('salesPost')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.SalesPost}>
                            {getLangText('salesPost')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('editionLabel')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.SalesPost}
                    className="button back-to-panels sm:mb-5 sm:mt-0 mt-3.5">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToList')}
                </Link>
            </div>
            <SalesPostEditWrapper
                postId={values.shop_post_id}
                title="salesStatistics"
            >
                {isWaiting ?
                    <ComponentLoader /> :
                    <div className="grid sm:grid-cols-5 gap-5">
                        <div className="sm:col-span-3">
                            <TopSummary
                                currency={values.currency}
                                values={values}
                                hasStats={hasStats}
                                setShowNoStatsModal={setShowNoStatsModal}
                            />
                            <LiveSale
                                live_views={values?.live_views}
                                currency={values.currency}
                                items={values.last_purchases}
                                hasStats={hasStats}
                                setShowNoStatsModal={setShowNoStatsModal}
                            />
                        </div>
                        <div className="sm:col-span-2">
                            {values.shop_post_id &&
                                <PostNotification
                                    loadData={loadData}
                                    values={values}
                                />
                            }
                            <VideoPreview values={values}/>
                        </div>
                    </div>
                }
            </SalesPostEditWrapper>
            {showNoStatsModal &&
                <NoDataModal
                    modalVisible={showNoStatsModal}
                    hideModal={() => setShowNoStatsModal(false)}
                />
            }
        </>
    );
};

export default SalesPostStatsForm;
