export const RouterPaths = {
	Auth: '/auth',
	Login: '/auth/login/',
	ForgetPassword: '/auth/forget-password/',
	Register: '/auth/register/',
	LoginGoogle: '/auth/google/',
	LoginFacebook: '/auth/facebook/',
	LoginGoogleExpansion: '/auth/google/expansion',
	LoginFacebookExpansion: '/auth/facebook/expansion',
	ResetPassword: '/auth/reset-password/:id',
	AuthSinglePage: '/auth/new',
	AuthEmployee: '/auth/employee',
	AuthEmployeeFull: '/auth/employee/:hash',
	AuthInstagram: '/auth/instagram/',
	LoginInstagram: '/auth/instagram/login/',
	AuthInstagramConnect: '/auth/instagram/connect',
	AuthInstagramProgress: '/auth/instagram/progress',
	RegisterInstagram: '/auth/instagram/signup/',

	MobileNotificationsAuthConfirmEmail: '/mobile/confirm/:id',
	MobileNotificationsAuthForgetPassword: '/mobile/forget-password/:id',
	MobileNotificationsAuth: '/mobile',

	LandingWebinarConfirm: '/landing/confirm/:id',

	Logout: '/wyloguj',

	RefreshToken: '/refresh-token/',
	Tutorial: '/tutorial/',
	TutorialOrigin: '/tutorial/origin',
	TutorialDetails: '/tutorial/details',
	TutorialFacebook: '/tutorial/facebook/',
	TutorialSocials: '/tutorial/socials/',
	TutorialSocialsInstagram: '/tutorial/socials/instagram',
	TutorialStart: '/tutorial/start/',
	TutorialOrder: '/tutorial/order/',
	TutorialInstagram: '/tutorial/instagram/',
	TutorialDelivery: '/tutorial/delivery/',
	TutorialPayment: '/tutorial/payment/',
	TutorialBuilding: '/tutorial/building/',
	TutorialInstagramConnect: '/tutorial/instagram/connect',
	TutorialInstagramProgress: '/tutorial/instagram/progress',

	LiveList: '/transmisje',
	LivePlatformStorageListFull: '/transmisje/platforma/magazyn/:id/',
	LivePlatformStorageList: '/transmisje/platforma/magazyn',
	LivePlatformActiveListFull: '/transmisje/platforma/sprzedaz/:id/',
	LivePlatformActiveList: '/transmisje/platforma/sprzedaz',
	LivePlatformShownListFull: '/transmisje/platforma/pokazane/:id/',
	LivePlatformShownList: '/transmisje/platforma/pokazane',
	LivePlatformNotShownListFull: '/transmisje/platforma/niepokazane/:id/',
	LivePlatformNotShownList: '/transmisje/platforma/niepokazane',
	LiveDetails: '/transmisje/:id/',

	Vacation: '/summer-promotion/',

	LiveOverlay: '/overlay',
	LiveOverlayForm: '/overlay/:id',

	Messenger: '/wiadomosci/',
	MessengerConversation: '/wiadomosci/:conversationId/',

	OrdersList: '/zamowienia',
	OrderForm: '/zamowienia/:id/',
	Order: '/zamowienie/:id/',

	ClientsList: '/klienci',
	ClientsSubmittedList: '/klienci/zapisani',
	ClientDetails: '/klienci/:id/',

	ProductsList: '/magazyn',
	ProductsLatestList: '/magazyn/kupione',
	ProductsReserveList: '/magazyn/lista-rezerwowa',
	ProductsExpendituresList: '/magazyn/expenditures',
	ProductsRemovedList: '/magazyn/removed',
	ProductsLive: '/magazyn/live',
	Product: '/magazyn/edytuj/:id/',

	CategoriesList: '/magazyn/kategorie',
	Category: '/magazyn/kategorie/:id/',
	CategoryEdit: '/magazyn/kategorie/:id/edytuj',
	SubCategoryEdit: '/magazyn/kategorie/:id/:categoryId',
	SubCategory: '/magazyn/kategorie/:id/podkategorie',
	SubCategoryProducts: '/magazyn/kategorie/:id/produkty/:categoryId',
	CategoryProducts: '/magazyn/kategorie/:id/produkty',
	CategorySEO: '/magazyn/kategorie/:id/seo',
	ReelsList: '/magazyn/reels',
	ReelsForm: '/magazyn/reels/:id/',
	MobileReelsList: '/magazyn/mobile-reels',
	MobileReelsForm: '/magazyn/mobile-reels/:id/',

	Notifications: '/notifications',
	NotificationsSubmitted: '/notifications/submitted',

	Analytics: '/analytics',

	DMList: '/dm-list',

	Storage: '/storage',
	StorageSingle: '/storage/:id',
	StorageScanner: '/storage/scanner',
	StorageScannerOrderCard: '/storage/scanner/order/:id',
	StorageScannerProductCard: '/storage/scanner/product/:id',
	StorageScannerProductVariantCard: '/storage/scanner/product/:id/:variant_id',
	StorageShelfProduct: '/storage/scanner/product/:id/:variant_id',
	StorageShelfOrder: '/storage/:shelfId/order/:id',

	SalesPost: '/sales-post',
	SalesPostsStats: '/sales-post/stats',
	SalesPostsHowItWorks: '/sales-post/how-it-works/',
	SalesPostEdit: '/sales-post/:id/',
	SalesPostEditStats: '/sales-post/stats/:id',

	SelmoPro: '/selmo-pro',
	SelmoProOverlay: '/selmo-pro/overlay',
	SelmoProMrAutomate: '/selmo-pro/mr-automate',
	SelmoProDepot: '/selmo-pro/warehouse',
	MrAutomate: '/mr-automate',

	CollectionsList: '/magazyn/kolekcje',
	Collection: '/magazyn/kolekcje/:id/',

	ManufacturersList: '/magazyn/manufacturers',
	Manufacturer: '/magazyn/manufacturers/:id/',

	PartnersProgram: '/program-partnerski/',

	Stats: '/statystyki',
	StatsNew: '/stats-new',

	PaymentProcessing: '/payment-processing',
	InstagramCallback: '/instagram-callback',
	ShopCartAuth: '/cart-auth/:hash',

	KnowledgeBase: '/baza-wiedzy',
	SelmoPaczkaTerms: '/selmo-paczka-terms',
	KnowledgeBaseDetail: '/baza-wiedzy/:nameUrl/',

	SelmoPaczka: '/selmo-paczka/',
	SelmoPaczkaHistory: '/selmo-paczka/transactions/',
	SelmoPaczkaConfiguration: '/selmo-paczka/configuration/',

	Help: '/pomoc/',

	Payments: '/plan/',
	PaymentsThankYou: '/plan/thank-you',
	PaymentsTransferPage: '/payment/confirmation/',

	BasketSingle: '/koszyk',
	BasketSingleEN: '/cart',
	Basket: '/koszyk/:shopName/:magicUrl/',
	BasketEN: '/cart/:shopName/:magicUrl/',
	BasketTransfer: '/koszyk/:shopName/:magicUrl/platnosc/',
	BasketTransferEN: '/cart/:shopName/:magicUrl/platnosc/',

	CartTrackingNoParam: '/tracking',
	CartTracking: '/tracking/:trackingId',

	Checkout: '/checkout/:shopName/:productName/',
	Newsletter: '/newsletter/:shopName/:newsletterId/',

	News: '/news',
	Dashboard: '/',
	PaypalAutomationReturn: '/paypal-automation-return',

	Settings: '/settings/',
	SettingsOld: '/ustawienia/',
	SettingsShop: '/settings/shop/',
	SettingsPaymentsShipments: '/settings/payments-shipments/',
	SettingsProfile: '/settings/profile/',
	SettingsGeneral: '/settings/shop/general/',
	SettingsFacebookStream: '/settings/facebook-stream',
	SettingsFacebookPost: '/settings/facebook-stream/:id',
	SettingsTerms: '/settings/shop/terms/',
	SettingsProductCodes: '/settings/shop/custom-product-codes/',
	SettingsDiscountCodes: '/settings/shop/discount-codes/',
	SettingsPlatform: '/settings/platform/',
	SettingsDiscountCodesEdit: '/settings/shop/discount-codes/edit',
	SettingsDiscountCodesEditFull: '/settings/shop/discount-codes/edit/:id',
	SettingsPackages: '/settings/shop/package-types/',
	SettingsPackagesEdit: '/settings/shop/package-types/edit',
	SettingsPackagesEditFull: '/settings/shop/package-types/edit/:id/',
	SettingsVoiceMessage: '/settings/shop/voice-messages/',
	SettingsSettlements: '/settings/shop/settlements/',
	SettingsVoiceMessageEdit: '/settings/shop/voice-messages/edit',
	SettingsVoiceMessageEditFull: '/settings/shop/voice-messages/edit/:id/',
	SettingsMessenger: '/settings/shop/messenger/',
	SettingsAutomaticallyNotifications: '/settings/shop/automatic-notifications/',
	SettingsPayments: '/settings/payments/',
	SettingsPaymentsOld: '/ustawienia/platnosci/',
	SettingsPaymentsTransfer: '/settings/payments/transfer',
	SettingsPaymentsPaypal: '/settings/payments/paypal/',
	SettingsPaymentsPaypalAutomation: '/settings/payments/paypal-automation/',
	SettingsPaymentsLusopay: '/settings/payments/lusopay/',
	SettingsPaymentsMobilePay: '/settings/payments/mobilepay/',
	SettingsPaymentsLusopayEdit: '/settings/payments/lusopay/edit',
	SettingsPaymentsPickup: '/settings/payments/cash-on-delivery/',
	SettingsPaymentsShopPickup: '/settings/payments/in-store-payment/',
	SettingsPaymentsTransferEdit: '/settings/payments/transfer/:id/',
	SettingsPaymentsOnline: '/settings/payments/online-transfers/',
	SettingsAutomaticTransfer: '/settings/payments/automatic-transfers/',
	SettingsAutomaticTransferLanding: '/settings/payments/automatic-transfers/why',
	SettingsPaymentsOnlineLanding: '/settings/payments/online-transfers/why/',
	SettingsPaymentsStripe: '/settings/payments/online-transfers/stripe/',
	SettingsPaymentsStripeReAuth: '/settings/payments/online-transfers/stripe/reauth',
	SettingsPaymentsStripeReAuthOld: '/ustawienia/platnosci/przelewy-online/stripe/reauth',
	SettingsShipping: '/settings/payments-shipments/shipping/',
	SettingsShippingEdit: '/settings/payments-shipments/shipping/edit',
	SettingsShippingEditFull: '/settings/payments-shipments/shipping/edit/:id/',
	SettingsShippingCountryEditFull: '/settings/payments-shipments/shipping/countries/edit/:id/',
	SettingsShippingCountryEdit: '/settings/payments-shipments/shipping/countries/edit',
	SettingsIntegration: '/settings/integrations/',
	SettingsIntegrationOld: '/ustawienia/integracje/',
	SettingsIntegrationFakturownia: '/settings/integrations/fakturownia/',
	SettingsIntegrationPaxy: '/settings/integrations/paxy/',
	SettingsIntegrationMondialRelay: '/settings/integrations/mondial-relay/',
	SettingsIntegrationMondialRelayData: '/settings/integrations/mondial-relay/data/',
	SettingsIntegrationShipmondo: '/settings/integrations/shipmondo/',
	SettingsIntegrationWoocommerce: '/settings/integrations/woocommerce/',
	SettingsIntegrationShopify: '/settings/integrations/shopify/',
	SettingsIntegrationShopifyOld: '/ustawienia/integracje/shopify/',
	SettingsIntegrationShopifyCallback: '/settings/integrations/shopify/callback',
	SettingsIntegrationShopifyCallbackOld: '/ustawienia/integracje/shopify/callback',
	SettingsIntegrationPrestaShop: '/settings/integrations/prestashop/',
	SettingsIntegrationsGls: '/settings/integrations/gls/',
	SettingsIntegrationsGlsFrance: '/settings/integrations/gls-france/',
	SettingsIntegrationDpd: '/settings/integrations/dpd/',
	SettingsIntegrationDpdTemplates: '/settings/integrations/dpd/templates',
	SettingsIntegrationDpdTemplate: '/settings/integrations/dpd/templates/:id',
	SettingsIntegrationShipmondoData: '/settings/integrations/shipmondo/data/',
	SettingsIntegrationOrlen: '/settings/integrations/orlen/',
	SettingsIntegrationOrlenData: '/settings/integrations/orlen/data/',
	SettingsIntegrationFurgonetka: '/settings/integrations/furgonetka/',
	SettingsIntegrationFurgonetkaOld: '/ustawienia/integracje/furgonetka/',
	SettingsIntegrationInpost: '/settings/integrations/inpost/',
	SettingsIntegrationShippyPro: '/settings/integrations/shippypro/',
	SettingsIntegrationShippyProTemplates: '/settings/integrations/shippypro/templates',
	SettingsIntegrationShippyProData: '/settings/integrations/shippypro/data/',
	SettingsIntegrationShippyProTemplate: '/settings/integrations/shippypro/templates/:id',
	SettingsIntegrationCustom: '/settings/integrations/:id',
	SettingsAccount: '/settings/account/',
	SettingsAccountChangePassword: '/settings/account/password/',
	SettingsFacebook: '/settings/meta/facebook/',
	SettingsFacebookOld: '/ustawienia/facebook/',
	SettingsInstagram: '/settings/meta/instagram/',
	SettingsInstagramOld: '/ustawienia/instagram/',
	SettingsMeta: '/settings/meta/',
	SettingsTeam: '/settings/shop/team/',
	SettingsMobileApp: '/settings/shop/mobile-app/',
	SettingsQRProduct: '/settings/shop/qr-product/',
	SettingsTeamMembers: '/settings/shop/team/members',
	SettingsTeamMember: '/settings/shop/team/members/:id',
	SettingsTeamMemberHistory: '/settings/shop/team/members/history/:id',
	SettingsTeamInvitations: '/settings/shop/team/invitations',
	SettingsTeamStatistics: '/settings/shop/team/statistics',
	SettingsTeamActivityHistory: '/settings/shop/team/history',
	SettingsAutomations: '/settings/automations',
	SettingsAutomationsReserveList: '/settings/automations/reserve-list',
	SettingsAutomationsReserveListConfiguration: '/settings/automations/reserve-list/configuration',
	SettingsAutomationsOrderCancellation: '/settings/automations/order-cancellation',
	SettingsAutomationsOrderCancellationConfiguration: '/settings/automations/order-cancellation/configuration',
	SettingsAutomationsOrderCancellationOrders: '/settings/automations/order-cancellation/orders',
	SettingsAutomationsOrderPaymentReminderConfiguration: '/settings/automations/payment-reminder/configuration',
	SettingsAutomationsOrderPaymentReminder: '/settings/automations/payment-reminder',
	SettingsAutomationsOpenPackageClosingConfiguration: '/settings/automations/open-package-closing/configuration',
	SettingsAutomationsOpenPackageClosing: '/settings/automations/open-package-closing',
	SettingsAutomationsOpenPackageClosingOrders: '/settings/automations/open-package-closing/orders',
	SettingsAutomaticallyNotificationsOld: '/settings/automatic-notifications/',

	Shop: '/sklep',
	ShopDesktop: '/sklep/pulpit/',
	ShopGeneral: '/sklep/ogolne/',
	ShopMainPage: '/sklep/strona-glowna/',
	ShopDomain: '/sklep/domena/',
	ShopPromotions: '/sklep/promocje',
	ShopPromotionsMessages: '/sklep/komunikaty/',
	ShopPromotionsNewsletter: '/sklep/newsletter/',
	ShopRegulation: '/sklep/regulamin/',
	ShopPrivacy: '/sklep/poltiyka-prywatnosci/',
	ShopPages: '/sklep/strony',
	ShopPage: '/sklep/strony/:id',
	ShopIntegrations: '/sklep/integracje',
	ShopIntegrationsPixel: '/sklep/integracje/piksel',
	ShopIntegrationsGoogle: '/sklep/integracje/google-analytics',
	ShopIntegrationsTiktok: '/sklep/integracje/tiktok',

	Automation: '/automation',
	AutomationEdit: '/automation/edit',
	AutomationInteractions: '/automation/interactions',
	AutomationStatistics: '/automation/statistics',
	AutomationInteractionFull: '/automation/edit/:id/interactions',
	AutomationNewsletterFull: '/automation/edit/:id/newsletter',
	AutomationStatisticsFull: '/automation/edit/:id/statistics',
	AutomationEditFull: '/automation/edit/:id',

	SelmoShop: '/sklep-testowy/',
	MobileAppBackToApp: '/back-to-app/:id',

	Admin: '/admin/',
}

