import React from 'react';
import {Link} from "react-router-dom";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";
import useLang from "../../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../../routes/RouterPath";
import {getDate} from "../../../messenger/chatBox/components/Message";
import NoDataPlaceholder from "./NoDataPlaceholder";
import {variants} from "../../../../../src_shared/helpers/helper";
import {SHOP_ORIGIN_US} from "../../../shared/enums/ShopOrigins";

const values = [
    {id: 'sales_overall', label: 'incomeLabel'},
    {id: 'qty_overall', label: 'soldPieces'},
    {id: 'sales_rl_overall', label: 'reservationValue'},
    {id: 'qty_rl_overall', label: 'reserveListLabel'},
    // {id: 'facebook_published_date', label: 'visibleOnFacebook'},
    // {id: 'selmo_app_publish_date', label: 'visibleInSelmoApp1'},
]

const visibilityValues = [
    {id: 'facebook_published_date', label: 'FB'},
    {id: 'selmo_app_publish_date', label: 'Selmo App'},
]

const Card = ({item, setShowNoStatsModal, userData}) => {
    const {getLangText} = useLang();

    const getPiecesLang = (count) => {
        const variantsLang = {
            singular: getLangText('pieceLabel'),
            plural: getLangText('piecesLabel'),
            genitive: getLangText('piecesGenitiveLabel')
        }

        return variants(+count, variantsLang)
    }

    const hasStats = !+item.added_on_facebook;

    return (
        <Link
            to={`${RouterPaths.SalesPostsStats}/${item.id}`}
            className="w-full block rounded-lg shadow-sm p-3 bg-white hover:no-underline hover:text-[#000] relative"
        >
            <figure className="h-[165px] mb-3 relative bg-[#eeeff2] flex items-center justify-center rounded-lg">
                {item.poster_url ?
                    <img
                        className={`w-full h-full object-cover rounded-lg ${!hasStats ? 'opacity-25' : ''}`}
                        src={item.poster_url} alt={item.poster_url}/> :
                    <img
                        className="w-[32px] h-[32px]"
                        src="/assets/images/default/photo-default.svg" alt=""/>
                }
                {hasStats &&
                    <div
                        className="absolute flex items-center top-1.5 right-1.5 rounded-[5px] bg-white shadow-sm p-1 text-center text-[10px] font-bold">
                        <img
                            className="w-[16px] mr-1"
                            src="/assets/images/subscription/selmo.svg" alt=""/>
                        {getLangText('addedBySelmo')}
                    </div>
                }
            </figure>
            <div>
                <div className="grid grid-cols-2 gap-2">
                    {values.map((value) => (
                        hasStats ?
                            <div className="text-xs p-3 border rounded-[5px]">
                                <div className="font-bold text-desc text-[10px]">
                                    {getLangText(value.label)}
                                </div>
                                {value.id === 'qty_overall' || value.id === 'qty_rl_overall' ?
                                    <div className="font-bold text-sm">
                                        {item[value.id]}
                                        <span className="text-xs ml-1 text-desc">
                                            {getPiecesLang(item[value.id])}
                                        </span>
                                    </div> :
                                    userData.origin === SHOP_ORIGIN_US ?
                                        <div className="font-bold text-sm">
                                            <span className="text-xs text-desc">
                                               {userData?.currency_info?.text}
                                            </span>
                                            {item[value.id]}
                                        </div> :
                                        <div className="font-bold text-sm">
                                            {item[value.id]}
                                            <span className="text-xs ml-1 text-desc">
                                               {userData?.currency_info?.text}
                                        </span>
                                        </div>

                                }
                            </div> :
                            <div className="text-xs px-3 space-y-1 py-[20px] border rounded-[5px]">
                                <div className="w-[50%] bg-[#F3F4F6] rounded-[5px] h-[12px]"/>
                                <div className="w-[60%] bg-[#F3F4F6] rounded-[5px] h-[12px]"/>
                            </div>
                    ))}
                </div>
                <div className="mt-3 flex items-center">
                    <div className="text-[12px] font-medium text-desc capitalize">
                        {!!convertToUserTimeZone(item.date).isSame(convertToUserTimeZone(), 'day') &&
                            `${getLangText('todayLabel').toLowerCase()}, `
                        }
                        {getDate(item.date)}
                    </div>
                    {/*{hasStats &&*/}
                    {/*    <div className="space-x-3 flex items-center ml-auto">*/}
                    {/*        {visibilityValues.map((value) =>*/}
                    {/*            <div className="flex items-center">*/}
                    {/*                {item[value.id] ?*/}
                    {/*                    <img className="min-w-[14px]" alt="tick" width={14}*/}
                    {/*                         src="/assets/images/svg/circle-check.svg"/> :*/}
                    {/*                    <img className="min-w-[14px]" alt="tick" width={14}*/}
                    {/*                         src="/assets/images/settings/cross.svg"/>*/}
                    {/*                }*/}
                    {/*                <div*/}
                    {/*                    className="text-xs font-medium text-desc ml-1">{getLangText(value.label) || value.label}</div>*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {hasStats &&
                        <div
                            className="flex items-center ml-auto text-desc text-xs leading-[14px]">
                            <div className="font-medium mr-1">Post</div>
                            <div className="font-bold">#{item.shop_post_id}</div>
                        </div>
                    }
                </div>
            </div>
            {!hasStats &&
                <NoDataPlaceholder setShowNoStatsModal={setShowNoStatsModal}/>
            }
        </Link>
    );
};

export default Card;
