import React from 'react';
import CreatePostButton from "./CreatePostButton";
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";

const HowItWorksComponent = () => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    return (
        <div className="mt-3 bg-[white] rounded-[5px] shadow-default py-12 sm:py-[100px] shop-landing-wrapper">
            <section
                style={{borderBottom: 0}}
                className="text-center">
                <div className="font-bold text-3xl sm:text-[42px] sm:leading-[52px] mb-2 max-w-[580px] mx-auto">
                    {getLangText('addFacebookPostEasier')}
                </div>
                <div
                    className="text-desc font-medium text-sm whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{__html: getLangText('addFacebookPostEasierSubLabel')}}
                >
                </div>
                <CreatePostButton big/>
            </section>
            <section
                className="padd-small-section"
                style={{borderBottom: 0}}
            >
                <div className="grid sm:grid-cols-2 gap-8">
                    <div className="sm:mr-4">
                        <div className="section-sub-title">
                            {getLangText('salesPost')}
                        </div>
                        <h1 className="section-title font-bold"
                            dangerouslySetInnerHTML={{__html: getLangText('analyzeSale')}}/>
                        <p className="desc">
                            {getLangText('analyzeSaleSubLabel')}
                        </p>
                        <div className="sm:hidden mt-3">
                            <img
                                className="rounded-[15px]"
                                width={788}
                                src={`/assets/images/multiLanguage/${lang}/salesPosts/1.webp`}
                                alt=""/>
                        </div>
                        <CreatePostButton/>
                    </div>
                    <div className="sm:block hidden">
                        <img
                            className="rounded-[15px]"
                            width={788}
                            src={`/assets/images/multiLanguage/${lang}/salesPosts/1.webp`}
                            alt=""
                        />
                    </div>
                </div>
            </section>
            <section
                className="padd-small-section"
                style={{borderBottom: 0}}
            >
                <div className="grid sm:grid-cols-2 gap-8">
                    <div className="sm:block hidden">
                        <img
                            className="rounded-[15px]"
                            width={788} src={`/assets/images/multiLanguage/${lang}/salesPosts/2.webp`} alt=""/>
                    </div>
                    <div className="sm:ml-4">
                        <div className="section-sub-title">
                            {getLangText('salesPost')}
                        </div>
                        <h1 className="section-title font-bold"
                            dangerouslySetInnerHTML={{__html: getLangText('addPostBySelmoFast')}}/>
                        <p className="desc">
                            {getLangText('addPostBySelmoFastSubLabel')}
                        </p>
                        <div className="sm:hidden mt-3">
                            <img
                                className="rounded-[15px]"
                                width={788} src={`/assets/images/multiLanguage/${lang}/salesPosts/2.webp`}
                                alt=""/>
                        </div>
                        <CreatePostButton/>
                    </div>
                </div>
            </section>
            <section
                className="padd-small-section"
                style={{borderBottom: 0}}
            >
                <div className="grid sm:grid-cols-2 gap-8">
                    <div className="sm:mr-4">
                        <div className="section-sub-title">
                            {getLangText('salesPost')}
                        </div>
                        <h1 className="section-title font-bold"
                            dangerouslySetInnerHTML={{__html: getLangText('generateDescriptionsWithProductCodes')}}/>
                        <p className="desc">
                            {getLangText('generateDescriptionsWithProductCodesSubLabel')}
                        </p>
                        <div className="sm:hidden mt-3">
                            <img
                                className="rounded-[15px]"
                                width={788} src={`/assets/images/multiLanguage/${lang}/salesPosts/3.webp`}
                                alt=""/>
                        </div>
                        <CreatePostButton/>
                    </div>
                    <div className="sm:block hidden">
                        <img
                            className="rounded-[15px]"
                            width={788} src={`/assets/images/multiLanguage/${lang}/salesPosts/3.webp`}
                            alt=""/>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HowItWorksComponent;
