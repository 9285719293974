import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import {NavLink, useParams} from "react-router-dom";
import React, {useMemo} from "react";

const tabs = [
    {id: 1, path: RouterPaths.SalesPostsStats, label: 'salesStatistics'},
    {id: 0, path: RouterPaths.SalesPost, label: 'editionLabel'},
]

const SalesPostEditWrapper = ({title, children, postId = ''}) => {
    const {getLangText} = useLang();

    const {id} = useParams();

    const preparedTabs = useMemo(() => postId ? tabs : tabs.slice(0, 1), [postId])

    return (
        <div className={`loader-parent list-with-tabs separated-box rounded-b-lg`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText(title)}</title>
            </Helmet>
            <div className="tabs-header mb-3 rounded-[5px] shadow-default bg-[white]">
                <div
                    className="page-title text-[18px] leading-[23px] font-bold px-[18px] pt-[23px] bg-[white] rounded-[5px]">
                    {postId ?
                        <div>
                            Post
                            <span className="text-desc ml-1 text-[16px] font-bold">{`#${postId}`}</span>
                        </div>:
                        <span
                            className="text-[16px] font-bold"
                            dangerouslySetInnerHTML={{__html: getLangText('postAddedByFacebook')}}
                        />
                    }
                </div>
                <div
                    style={{borderBottom: 0}}
                    className="tabs-wrapper rounded-b-[5px] bg-[white]"
                >
                    {preparedTabs.map((i) => (
                        <NavLink
                            exact
                            to={`${i.path}/${id}`}
                            key={i.id}
                            activeClassName="active"
                        >
                            {getLangText(i.label)}
                        </NavLink>
                    ))}
                </div>
            </div>
            {children}
        </div>
    );
};

export default SalesPostEditWrapper;
