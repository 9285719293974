import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {useHistory} from "react-router-dom";
import useSubmitData from "../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../RestServices";
import {RouterPaths} from "../../../routes/RouterPath";
import Loader from "../../../../src_shared/components/loader/Loader";

const CreatePostButton = ({big = false}) => {

    const {getLangText} = useLang();
    const history = useHistory();

    const [{onSave: createPost, isWaiting: isWaitingForPost}] = useSubmitData({
        rest: services.API_VIDEO_SOCIAL_VIDEOS,
        afterSubmit: (response) => history.push(`${RouterPaths.SalesPost}/${response.id}`)
    });

    if (big) return (
        <button
            type="button"
            onClick={createPost}
            className={`button primary mt-3 ${isWaitingForPost ? 'loading-button' : ''}`}
            style={{fontSize: 16, borderRadius: 8}}
        >
            {isWaitingForPost && <Loader isLoading/>}
            {getLangText('addPost')}
        </button>
    )

    return (
        <button
            type="button"
            onClick={createPost}
            className={`button primary mt-3 ${isWaitingForPost ? 'loading-button' : ''}`}
            style={{fontSize: 14, borderRadius: 8}}
        >
            {isWaitingForPost && <Loader isLoading/>}
            {getLangText('addPost')}
        </button>
    );
};

export default CreatePostButton;
